import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import Header from '@solid-ui-blocks/Header/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import theme from '@flexiblocks/templates/saas-v2/_theme'
import styles from '@flexiblocks/templates/saas-v2/_index.styles'

const CancelPage = props => {
  const { allBlockContent } = useStaticQuery(query)
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout theme={theme} {...props}>
      <Seo title='Rhodium11' />
      {/* Blocks */}
      <Header content={content['header1']} menuJustify='space-between' />
      <Divider space='5' />
      <Container sx={styles.tabsContainer}>
        <h1 css={{textAlign:"center",fontsize:"64px"}}>Purchase failed. If this is a technical error, please contact us (link that opens Contact popup).</h1>
        <h4 css={{textAlign:"center"}}><a href="/" css={{padding: "20px 40px",background: "#3dcfbe",color: "#fff",borderRadius: "30px",textDecoration: "none",}}>Go back Home</a></h4>
      </Container>
    </Layout>
  )
}

const query = graphql`
  query siteCancelBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/index", "site/shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default CancelPage
